module.exports = {
  "defaultCurrency": "CAD",
  "currencies": [
    {
      "id": 3,
      "code": "CAD",
      "name": "CAD"
    },
    {
      "id": 2,
      "code": "USD",
      "name": "$USD"
    },
    {
      "id": 1,
      "code": "EUR",
      "name": "€EUR"
    },
    {
      "id": 4,
      "code": "AUD",
      "name": "$AUD"
    },
    {
      "id": 5,
      "code": "GBP",
      "name": "£GBP"
    }
  ]
}